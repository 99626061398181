import React from 'react'
import '../News/News.css'
import { Link } from 'react-router-dom'

//Assets
import shreeKant from "../../../assets/images/srikant press release (2).png"

const  News=()=> {
    
    const array=[
        {
            img:`https://cdn.ellobus.in/greencell-uat/images/_MG_9561_300%20X%20300%20px_Desktop%20Homepage%20delhi.jpg`,
            paragragh:`NueGo Flags Off Historic Kashmir to Kanyakumari (E-K2K) Electric Bus Expedition`,
            link:"#Blog1"
        },
        {
            img:`https://cdn.ellobus.in/greencell-uat/images/_MG_1847_300%20X%20300%20px_Desktop%20Homepage.jpg`,
            paragragh:"Union Minister Sh. Nitin Gadkari flags off NueGo’s historic Kashmir to Kanyakumari(E-K2K) Electric Bus expedition from Nagpur",
            link:"#Blog2"
        },
        {
            img:`https://cdn.ellobus.in/greencell-uat/images/NueGo_300%20X%20300%20px_Desktop%20Homepage.jpg`,
            paragragh:"GreenCell Mobility’s NueGo Becomes India’s First Premium Intercity Electric Coach Service to Operate in More than 100 Cities Across the Country",
            link:"#Blog3"
        },
  
    ]

    return (

        <section className='news_section'>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Recent News</h2>
                        <div className='news-list'>
                        <ul>
                            {array.map((items,id)=>{
                              return  <li  className='imagenewsss' >                                   
                              <img src={items.img} alt="blog1"  className='imagessss' />
                              <p>{items.paragragh} ​</p>
                              <Link  to={`/news${items.link}`} >Read More 
                               {/* <img src={blogarrow} alt='blogarrow img' />  */}
                               <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/arrow.svg`} alt='blogarrow img'    />   
                               </Link>
                            </li>
                            }) }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    )
}

export default News

import React from 'react'
import '../Glance/Glance.css'
// import glanceimg from '../../../assets/images/img3.svg'
// import Busbg from "../../../assets/images/bus-bg.png"

// import Busbgggggbg from "../../../assets/images/bus-bg.png"



function Glance() {

    // const glance_style =  {
    //     backgroundImage : "url(" + { Busbg } + ")" 
    // }
    
    return (
        <>

            <section className='glance_section'>
                <div className="container">
                    <div className="row">
                        <div className="col-12"> 

                            <div className="glance-part">
                                <div className="left-part">
                                    <h2>Statistics at a Glance</h2>
                                    {/* <img src={glanceimg} alt="glanceimg" className='w-100' /> */}
                                    <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/img3.svg`} alt="glanceimg" className='w-100' />

                                </div>
                                <div className="right-part">
                                    <p>GreenCell wants to be the prime mover in building a sustainable growth momentum in the shared e-mobility space.</p>
                                    <ul>
                                        <li className='mb-4'>
                                            <h3> 1,150+ </h3>
                                            <span className='d-flex'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C8.22876 2 6.34315 2 5.17157 3.17157C4.10848 4.23467 4.01004 5.8857 4.00093 9H3C2.44772 9 2 9.44772 2 10V11C2 11.3148 2.14819 11.6111 2.4 11.8L4 13C4.00911 16.1143 4.10848 17.7653 5.17157 18.8284C5.41375 19.0706 5.68645 19.2627 6 19.4151V20.9999C6 21.5522 6.44772 21.9999 7 21.9999H8.5C9.05228 21.9999 9.5 21.5522 9.5 20.9999V19.9815C10.2271 20 11.0542 20 12 20C12.9458 20 13.7729 20 14.5 19.9815V20.9999C14.5 21.5522 14.9477 21.9999 15.5 21.9999H17C17.5523 21.9999 18 21.5522 18 20.9999V19.4151C18.3136 19.2627 18.5862 19.0706 18.8284 18.8284C19.8915 17.7653 19.9909 16.1143 20 13L21.6 11.8C21.8518 11.6111 22 11.3148 22 11V10C22 9.44772 21.5523 9 21 9H19.9991C19.99 5.8857 19.8915 4.23467 18.8284 3.17157C17.6569 2 15.7712 2 12 2ZM5.5 9.5C5.5 10.9142 5.5 11.6213 5.93934 12.0607C6.37868 12.5 7.08579 12.5 8.5 12.5H12H15.5C16.9142 12.5 17.6213 12.5 18.0607 12.0607C18.5 11.6213 18.5 10.9142 18.5 9.5V7C18.5 5.58579 18.5 4.87868 18.0607 4.43934C17.6213 4 16.9142 4 15.5 4H12H8.5C7.08579 4 6.37868 4 5.93934 4.43934C5.5 4.87868 5.5 5.58579 5.5 7V9.5ZM6.25 16C6.25 15.5858 6.58579 15.25 7 15.25H8.5C8.91421 15.25 9.25 15.5858 9.25 16C9.25 16.4142 8.91421 16.75 8.5 16.75H7C6.58579 16.75 6.25 16.4142 6.25 16ZM17.75 16C17.75 15.5858 17.4142 15.25 17 15.25H15.5C15.0858 15.25 14.75 15.5858 14.75 16C14.75 16.4142 15.0858 16.75 15.5 16.75H17C17.4142 16.75 17.75 16.4142 17.75 16Z" fill="#0A8C44" fill-opacity="0.7" />
                                            </svg>

                                            <p className='ps-2'>Buses Deployed</p>
                                            </span>
                                        </li>

                                        <li className='mb-4'>
                                            <h3>212 Mn+ </h3>
                                            <span className='d-flex'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.1 4.8C18 4.3 17.6 4 17.1 4H13L13.2 7H10.8L11 4H6.80002C6.30002 4 5.90002 4.4 5.80002 4.8L3.10002 18.8C3.00002 19.4 3.50002 20 4.10002 20H10L10.3 15H13.7L14 20H19.8C20.4 20 20.9 19.4 20.8 18.8L18.1 4.8ZM10.4 13L10.6 9H13.2L13.4 13H10.4Z" fill="#0A8C44" fill-opacity="0.7" />
                                            </svg>

                                            <p className='ps-2'>KMs Covered</p>
                                            </span>
                                        </li>

                                        <li className='mb-4'>
                                            <h3> 250+ </h3>
                                           <span className='d-flex  '>  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20.8001 6.39961V3.98361C20.7915 3.54809 20.4358 3.19945 20.0001 3.19961C19.5495 3.20713 19.1902 3.57833 19.1977 4.02905C19.198 4.04585 19.1988 4.06281 19.2001 4.07961V7.99961C19.2001 8.88329 19.9164 9.59961 20.8001 9.59961V18.3996C20.8001 18.8414 20.4419 19.1996 20.0001 19.1996C19.5583 19.1996 19.2001 18.8414 19.2001 18.3996V15.1996C19.2001 13.8742 18.1255 12.7996 16.8001 12.7996H14.4001V3.19961C14.4001 2.31593 13.6838 1.59961 12.8001 1.59961H3.2001C2.31642 1.59961 1.6001 2.31593 1.6001 3.19961V20.7996C1.6001 21.6833 2.31642 22.3996 3.2001 22.3996H12.8001C13.6838 22.3996 14.4001 21.6833 14.4001 20.7996V14.3996H16.8001C17.2419 14.3996 17.6001 14.7578 17.6001 15.1996V18.3996C17.6001 19.725 18.6747 20.7996 20.0001 20.7996C21.3255 20.7996 22.4001 19.725 22.4001 18.3996V7.99961C22.4001 7.11593 21.6838 6.39961 20.8001 6.39961ZM11.5207 11.8388L7.35978 17.3866L7.33354 17.3751C7.25914 17.501 7.14682 17.5996 6.99018 17.5996C6.75402 17.5996 6.56266 17.4082 6.56266 17.1721C6.56266 17.1273 6.59738 17.098 6.61018 17.0574L6.58186 17.0449L7.82442 13.3257C7.91082 13.067 7.71818 12.7996 7.44538 12.7996H4.79914C4.46986 12.7996 4.28202 12.4238 4.47946 12.1604L8.64042 6.61257L8.66666 6.62409C8.74106 6.49817 8.85338 6.39961 9.01002 6.39961C9.24618 6.39961 9.43754 6.59097 9.43754 6.82713C9.43754 6.87193 9.40282 6.90121 9.39002 6.94185L9.41818 6.95433L8.17594 10.6734C8.08938 10.9322 8.28202 11.1996 8.55482 11.1996H11.2011C11.5303 11.1996 11.7182 11.5754 11.5207 11.8388Z" fill="#0A8C44" fill-opacity="0.7" />
                                            </svg>

                                            <p className='ps-2'>Charging stations</p>
                                            </span>
                                        </li>
                                        <li className='mb-4'>
                                            <h3> 190 Mn+ </h3>
                                            <span className='d-flex'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.7521 12.435C17.1677 12.1421 17.5515 11.8124 17.8999 11.4514C18.2164 12.5217 18.4 13.5975 18.4 14.4002C18.4 16.0659 18.0518 17.6617 17.1835 18.8006C16.3913 19.8396 14.9362 20.8002 12 20.8002C9.97479 20.8002 8.4113 20.28 7.37554 19.357C6.38062 18.4704 5.59998 16.9695 5.59998 14.4002C5.59998 12.3208 6.48728 11.3457 7.9567 9.73092C7.99731 9.6863 8.03836 9.64118 8.07986 9.59554C8.83873 8.76078 9.751 7.72829 10.4452 6.36294C10.6671 5.92658 10.8629 5.46468 11.0273 4.97194C11.3908 5.37079 11.7028 5.78086 11.9748 6.21211C12.7417 7.42797 13.2746 8.94656 13.6256 11.1191C13.7355 11.8 14.1885 12.3761 14.8242 12.6436C15.4598 12.9111 16.1884 12.8323 16.7521 12.435Z" stroke="#0A8C44" stroke-opacity="0.7" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            <p className="ps-2"> (kgs of tail-pipe  <br/> emissions avoided)</p>
                                            </span>
                                           
                                        </li>
                                    </ul>
                                    <div id='disclaimer'><span>(*until Dec 2024)</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
            <div className='glance_bg' style={{backgroundImage:'url(https://cdn.ellobus.in/greencell-uat/images/bus-bg.jpg)'}}> 

            </div>

        </>
    )
}

export default Glance

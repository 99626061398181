import React , {useEffect} from 'react'
import Layout from '../../AllComponents/Layout'
import './newsPage.css'
import bollycrew from "../../../assets/images/bollyCrew.png"
import sleeperSeeter from "../../../assets/images/NueGo Seater + Sleeper Electric AC Bus.jpeg"
import shreeKanth from "../../../assets/images/srikant press release (2).png"



const NewsPage = () => {

    useEffect(() => {
        // Get the hash from the URL (e.g., #specificDiv)
        const hash = window.location.hash;
    
        // Scroll to the element with the corresponding ID
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []);

    let array = [
        {
            "heading":"NueGo teams up with ‘Brother,’ a Tamil comedy-drama featuring Jayam Ravi and Priyanka Mohan",
            "paragrapgh":"GreenCell Mobility's NueGo, India’s leading electric intercity bus brand, is thrilled to announce its partnership with the highly anticipated Tamil-language comedy-drama film 'Brother'. Starring Jayam Ravi and Priyanka Mohan, the film is written and directed by M. Rajesh and produced by Sundar Arumugam under the banner of Screen Scene Media Entertainment Pvt. Ltd.",
            "link": "https://www.medianews4u.com/nuego-teams-up-with-brother-a-tamil-comedy-drama-featuring-jayam-ravi-and-priyanka-mohan/#:~:text=Tamil%20Nadu%3A%20GreenCell%20Mobility's%20NueGo,written%20and%20directed%20by%20M.",
            "image": `https://cdn.ellobus.in/greencell-uat/images/NueGo-x-Brother_450-X-350-px_Desktop-News-Pg.jpg`,
            "height": "350px",
            // "id": "Blog3"
        },
        {
            "heading":"NueGo Expands Inter-City Network with New Routes Across India",
            "paragrapgh":"NueGo, India’s leading electric intercity brand by GreenCell Mobility, proudly announces the launch of new intercity routes across the country. The brand has introduced 6 new routes this festive season, that reinforce its commitment to sustainable mass mobility while offering guests a premium and comfortable travel experience.",
            "link": "https://www.traveltrendstoday.in/nuego-expands-inter-city-network-with-new-routes-across-india/",
            "image": `https://cdn.ellobus.in/greencell-uat/images/NueGo%20Hybrid%20Bus_01__450%20X%20350%20px_Desktop%20News%20Pg.jpg`,
            "height": "350px",
            // "id": "Blog3"
        },
        {
            "heading":"Setting New Standards in Women’s Safety for Bus Travel",
            "paragrapgh":"Ensuring safe travel for women remains a critical priority in India, especially for those journeying alone. From bustling metropolitan areas like Delhi, Bangalore, and Chennai to growing cities such as Bhopal and Agra, many women continue to express concerns about their safety while traveling. This is particularly evident during intercity journeys, highlighting the urgent need for transportation options that prioritize passenger security.",
            "link": "https://madeinmedia.in/setting-new-standards-in-womens-safety-for-bus-travel/",
            "image": `https://cdn.ellobus.in/greencell-uat/images/NueGo_450%20X%20350%20px_women_Desktop%20News%20Pg.jpg`,
            "height": "350px",
            // "id": "Blog3"
        },
        {
            "heading":`Shri Jagadeesh, Deputy Superintendent of Police, Flags Off Southern Leg of NueGo’s Kashmir to Kanyakumari Expedition` ,
            "paragrapgh":`In its mission to promote sustainable transportation across India, NueGo—the country’s first organized electric intercity brand under GreenCell Mobility—flagged off the Southern leg of its iconic Kashmir to Kanyakumari (K2K) electric bus expedition today at its Bangalore Depot. The Bangalore flag-off ceremony was graced by Shri Jagadeesh, Deputy Superintendent of Police, Nelamangala, Bangalore, along with other distinguished dignitaries like Mr. Manjunath, Commissioner, City Municipal Corporation, Mr. Ravikumar, Traffic Police Inspector, Nelamangala, Mr. K.M. Siddahonnappa, Fire Station Officer, Nelamangala, Mr. Murlidhar, Police Inspector, Madanayakanahalli` ,
            "link": "https://metrotimesindia.com/shri-jagadeesh-deputy-superintendent-of-police-flags-off-southern-leg-of-nuegos-kashmir-to-kanyakumari-expedition-k2k-electric-bus-expedition-today-at-its-bangalore-depot/#:~:text=KarnatakaLatest%20News-,Shri%20Jagadeesh%2C%20Deputy%20Superintendent%20of%20Police%2C%20Flags%20Off%20Southern%20Leg,today%20at%20its%20Bangalore%20Depot&text=The%20K2K%20electric%20bus%20expedition,now%20entering%20the%20southern%20phase.",
            "image": `https://cdn.ellobus.in/greencell-uat/images/_MG_3529_450%20X%20350%20px_southern_leg_Desktop%20News%20Pg.jpg`,
            "height": "350px",
            //"id": "Blog1",
            "objectFit":"cover"
        },
        {
            "heading":"Historic Culmination of NueGo’s Kashmir to Kanyakumari (E-K2K) Electric Bus Expedition in Kanyakumari",
            "paragrapgh":"NueGo, India’s premier electric intercity bus brand under GreenCell Mobility, proudly announced the successful completion of its historic Kashmir to Kanyakumari (E-K2K) electric bus expedition. This groundbreaking journey spanned over 4,000 kilometers, involved public engagement in more than 200 towns and cities, and marked a significant leap in the nation’s transition toward sustainable transportation.",
            "link": "https://www.instagram.com/reel/DBY6lF8oEZd/?igsh=Zjh6czdnNHJvaHFj",
            "image": `https://cdn.ellobus.in/greencell-uat/images/kanyakumari_culmination__450%20X%20350%20px_Desktop%20News%20Pg.jpg`,
            "height": "350px",
            //"id": "Blog2",
            "objectFit":"cover"
        },
        {
            "heading":"GreenCell Mobility’s NueGo Becomes India’s First Premium Intercity Electric Coach Service to Operate in More than 100 Cities Across the Country",
            "paragrapgh":"NueGo, the premium electric intercity mobility brand by GreenCell Mobility, has set a remarkable benchmark by emerging as India’s largest intercity travel network, now operating in over 100 cities. With an unwavering commitment to sustainability, innovation, and guest-centricity, NueGo continues to redefine intercity mobility, offering seamless and eco-friendly travel experiences.",
            "link": "https://www.autocarpro.in/news/acma-mobility-foundation-to-launch-inaugural-automotive-aftermarket-expo-in-patna--123845",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/newsRoom5.jpg`,
            "height": "350px",
            "id": "Blog3"
        },

        ////////
        {
            "heading":`NueGo Flags Off Historic Kashmir to Kanyakumari (E-K2K) Electric Bus Expedition` ,
            "paragrapgh":`
                    1. 1st bus brand in world to undertake Kashmir to Kanyakumari (E-K2K) expedition <br>
                    2. To cover 4000+ emission free Kms, 200+ towns & cities, from 3500 ft to sea level <br>
                    3.Journey aims to raise awareness of electric buses as a viable solution for massmobility through on-ground activities, fully embracing the spirit of "The E-bus that does good."
                    4.Supported by distinguished guests from NITI Aayog, The World Bank, Eversource Capital, NHEV, RMI, ICCT at official flag-off from Delhi NCR` ,
            "link": "https://www.motorindiaonline.in/nuego-flags-off-kashmir-to-kanyakumari-e-k2k-electric-bus-expedition/",
            "image": `https://cdn.ellobus.in/greencell-uat/images/delhi.jpg`,
            "height": "350px",
            "id": "Blog1",
            "objectFit":"cover"
        },
        {
            "heading":"Union Minister Sh. Nitin Gadkari flags off NueGo’s historic Kashmir to Kanyakumari(E-K2K) Electric Bus expedition from Nagpur",
            "paragrapgh":`Hon’ble Minister of Road Transport and Highways Sh.Nitin Gadkari shared “I am
delighted that NueGo has embarked on this historic electric bus journey from Kashmir to
Kanyakumari, covering over 4,000 kms, to raise awareness about electric mass mobility &
also conducting various impactful activities with students & communities, as it passes through
more than 200 towns and cities.`,
            "link": "https://www.cnbctv18.com/auto/nitin-gadkari-flags-off-nuegos-record-4000-km-kashmir-to-kanyakumari-electric-bus-journey-19491893.htm",
            "image": `https://cdn.ellobus.in/greencell-uat/images/_MG_1847_450%20X%20350%20px_Desktop%20News%20Pg.jpg`,
            "height": "350px",
            "id": "Blog2",
            "objectFit":"cover"
        },
        {
            "heading":"NueGo Partners with the Upcoming Film Vicky Vidya Ka Woh Wala Video Starring Rajkummar Rao and Triptii Dimri",
            "paragrapgh":" GreenCell Mobility's NueGo, India’s leading electric intercity bus brand,is excited to announce its collaboration with the much-awaited comedy film Vicky Vidya Ka Woh Wala Video. Starring Rajkummar Rao and Triptii Dimri, the film is co-written and directed by Raaj Shaandilyaa. This quirky and hilarious comedy is set to hit the theatres on 11th October 2024 ",
            "link": "https://www.adgully.com/nuego-collaborates-with-upcoming-film-vicky-vidya-ka-woh-wala-video-151724.html",
            "image": `https://cdn.ellobus.in/greencell-uat/images/NueGo-x-VVKWWV_450-X-350-px_Desktop-News-pg.jpg`,
            "height": "350px",
            // "id": "Blog3"
        },
        {
            "heading":"Bhopal Traffic DCP Shri Sanjay Singh and DSP Shri Pramod Dube flag off Central leg of NueGo’s Kashmir to Kanyakumari expedition",
            "paragrapgh":"With the aim to promote sustainable mass mobility in India, NueGo recently announced the launch of its historic Kashmir to Kanyakumari (K2K) electric bus expedition. Today, Bhopal Traffic DCP Shri Sanjay Singh and DSP Shri Pramod Dube flagged off the Western leg of this expedition in Bhopal. NueGo, a leader in electric mass mobility under GreenCell Mobility, is India's first organized electric intercity brand",
            "link": "https://indoremirror.in/bhopal-traffic-dcp-and-dsp-flag-off-central-leg-of-nuegos-kashmir-to-kanyakumari-expedition/",
            "image": `https://cdn.ellobus.in/greencell-uat/images/Bhp%203_450%20X%20350%20px.jpg`,
            "height": "350px",
            // "id": "Blog3"
        },
        {
            "heading":"Shri Gopalakrishna VC, Director of Automotive and EV – Government of Telangana Flags Off Southern Leg of NueGo’s Kashmir to Kanyakumari Expedition",
            "paragrapgh":"Hyderabad: In a bid to promote sustainable transportation across India, NueGo—the country’s first organized electric intercity brand under GreenCell Mobility flagged off the Southern leg of its iconic Kashmir to Kanyakumari (K2K) electric bus expedition today in Hyderabad. The flag-off was officiated by Shri Gopalakrishna VC, Director of Automotive and EV – Government of Telangana. This green journey highlights NueGo’s commitment to sustainable mobility and raising awareness about electric vehicles (EVs) across the nation",
            "link": "https://www.apnnews.com/shri-gopalakrishna-vc-director-of-automotive-and-ev-government-of-telangana-flags-off-southern-leg-of-nuegos-kashmir-to-kanyakumari-expedition/",
            "image": `https://cdn.ellobus.in/greencell-uat/images/_MG_2605_450%20X%20350%20px_Desktop%20News%20Pg.jpg`,
            "height": "350px",
            // "id": "Blog3"
        },
        {
            "heading":`NueGo Partners with the Exciting New Film "Khel Khel Mein" Starring Akshay Kumar, Vaani Kapoor, Taapsee Pannu, Ammy Virk, Fardeen Khan, Pragya Jaiswal & Aditya Seal` ,
            "paragrapgh":`This collaboration reflects NueGo's dedication to innovation, sustainability, and cultural engagement, which complements the film's dynamic and entertaining spirit. To commemorate this collaboration, NueGo is offering an exclusive 10% discount on all flights. Passengers can take advantage of this offer by booking through the NueGo App and Website (www.nuego.in) and using the code "KKM".` ,
            "link": "https://www.mediainfoline.com/alliances/nuego-partners-with-film-khel-khel-mein-starring-akshay-kumar-vaani-kapoor-taapsee-pannu",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/KKM_For-NueGo-Website_PR-Banner_Desktop-News-Pg_450-X-350-px.jpg`,
            "height": "350px",
            //"id": "Blog1",
            "objectFit":"cover"
        },
        {
            "heading":"NueGo celebrates 2 years of Milestones",
            "paragrapgh":"Two years ago, we embarked on a mission to redefine intercity mass mobility in India. Indians want to travel better, and NueGo was born out of a vision to deliver reliability, safety and comfort to our guests and contribute to a healthier planet. NueGo celebrates 2 years of Sustainable Growth with over 45 Million Emission free KMS.We are the First organized player & national brand in electric mass mobility to serve over 5 million guests and Avoided more than 30 Million Kgs of CO2 emissions and 10 million liters of Diesel in 2 years",
            "link": "https://www.news18.com/auto/nuego-celebrates-2-years-of-green-travel-milestones-with-over-45-million-emission-free-kms-9012910.html",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/anniversary2.webp`,
            "height": "350px",
            //"id": "Blog2",
            "objectFit":"cover"
        },
        {
            "heading":"GreenCell Mobility Celebrates Pride Month with Commitment to Diversity and Inclusion",
            "paragrapgh":"GreenCell Mobility, a leader in sustainable urban mass mobility solutions, proudly announces its celebration of Pride Month, reaffirming its commitment to fostering a workplace that values diversity, inclusion, and equality for all. As part of its initiatives during Pride Month, GreenCell Mobility is pleased to highlight the contributions of its LGBTQIA+ employees, who play integral roles across various departments, such as Coach Captains, Command Centre, Operations, Finance and Administration. ",
            "link": "https://www.mobilityindia.com/greencell-mobility-celebrates-pride-month-with-commitment-to-diversity-and-inclusion/",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/prideGCM.jpg`,
            "height": "350px",
            //"id": "Blog3"
        },
        {
            "heading":"NueGo Teams Up with Highly Anticipated Film 'Chandu Champion' Starring Kartik Aaryan",
            "paragrapgh":"GreenCell Mobility's collaboration highlights NueGo's dedication to sustainability and inclusivity, echoing both production houses’s commitment to eco-responsibility.To celebrate this partnership, NueGo is offering an exclusive 10% discount on all routes. One can avail this offer by simply using the code 'CHAMPION' when booking through the NueGo App and Website (www.nuego.in). This limited time offer, valid until 30th June 2024, provides eco-conscious travellers with a fantastic opportunity to enjoy eco-friendly intercity travel at a discounted rate.",
            "link": "https://www.storyboard18.com/brand-marketing/nuego-partners-with-bollywood-film-chandu-champion-starring-kartik-aaryan-33972.htm",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/chanduPR_img_resize.png`,
            "height": "350px",
            // "id": "Blog3"
        },
        {
            "heading":"GreenCell Mobility secures INR 3 Billion Green Financing from Sumitomo Mitsui Banking Corporation for 350 electric buses in Uttar Pradesh",
            "paragrapgh":"GreenCell Mobility, a pioneer in Electric Mass Mobility sector, has secured Green Financing from leading Japanese financial institution Sumitomo Mitsui Banking Corporation (SMBC) for its transformative Electric Bus project in Uttar Pradesh. This transaction not only marks the 1st Project Finance by a Japanese bank in the Electric Mobility domain in India but is also SMBC's 1st Project Finance transaction in E-Mobility in Asia Pacific.",
            "link": "https://energy.economictimes.indiatimes.com/amp/news/power/uttar-pradesh-greencell-mobility-bags-3-billion-financing-for-project-that-will-reduce-2-35-lakh-tonnes-of-co2-emissions/109980326",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/newsroom7.jpg`,
            "height": "350px",
            // "id": "Blog3"
        },
        {
            "heading":"NueGo Teams Up with Highly Anticipated T-Series Film “SRIKANTH” starring Rajkumar Rao",
            "paragrapgh":"As part of this venture, travellers can enjoy an exclusive 10% discount on all routes by using the code 'SRIKANTH' when booking exclusively through the NueGo App and Website (www.nuego.in). This limited-time offer, valid until May 30, 2024, presents a unique opportunity for eco-conscious travellers to experience safe and comfortable intercity travel during the summer season.",
            "link": "https://www.storyboard18.com/brand-marketing/nuego-teams-up-with-t-series-film-srikanth-starring-rajkummar-rao-30718.htm",
            "image": shreeKanth,
            "height": "350px",
            // "id": "Blog3"
        },
        {
            "heading":"NueGo Launches INDIA’S First Long-Haul Electric AC Seater + Sleeper Bus Service",
            "paragrapgh":"NueGo, a pioneer in sustainable mass mobility, is set to revolutionise the long-distance travel journey with the launch of India's first intercity electric bus service. NueGo intends to deploy multiple seater + sleeper buses on its major routes across India such as Bengaluru - Chennai, Bengaluru - Coimbatore, Vijaywada - Vizag, Delhi – Jaipur,  Delhi - Amritsar. NueGo is planning to expand its operations by launching new routes and build charging infrastructure to ensure smooth on-road operations. NueGo is also actively hiring across genders to increase inclusivity in its workforce and is the only bus brand to have women coach captains.",
            "link": "https://mediabrief.com/nuego-launches-long-haul-electric-ac-seater-sleeper-bus-service/",
            "image": sleeperSeeter,
            "height": "350px",
            // "id": "Blog3"
        },
        {
            "heading":"Electrifying Collaboration: NueGo Partners with Bollywood’s 'CREW,' starring Kareena Kapoor, Kriti Sanon, and Tabu",
            "paragrapgh":"GreenCell Mobility’s NueGo, India’s leading electric bus brand is thrilled to announce its partnership with the highly anticipated Bollywood film ”CREW” starring Kareena Kapoor, Tabu and Kriti Sanon, which is set to release on 29th March in theatres near you,As part of this partnership, travellers can avail an additional 10% discount on all routes by booking tickets exclusively through NueGo App and Website. This limited period offer will be active till 20th April 2024, providing a unique opportunity for travellers to experience safe, comfortable, and eco-friendly intercity transportation. ",
            "link": "https://www.afaqs.com/news/mktg/nuego-partners-with-bollywood-movie-crew-to-offer-exclusive-booking-discounts",
            "image": bollycrew,
            "height": "350px",
            //"id": "Blog2"
        },
        {
            "heading":"NueGo Forges Exciting Partnership as Official Sponsor of Haryana Steelers Kabaddi Team",
            "paragrapgh":"GreenCell Mobility's NueGo, a premier intercity electric AC bus service, is delighted to announce its latest collaboration as Associate Partner with the Haryana Steelers, India’s leading men's professional kabaddi team.NueGo's partnership with the Haryana Steelers extends the company's commitment to creating awareness of sustainable mass mobility, while actively engaging with sports enthusiasts throughout the country. NueGo hopes to intensify the excitement around kabaddi, a sport profoundly rooted in India's cultural legacy, by extending its sponsorship to Haryana Steelers team for the rest of the season.",
            "link": "https://www.afaqs.com/news/mktg/nuego-partners-with-haryana-steelers-kabbadi-team-to-promote-sustainable-mobility",
            "image": `https://nuego1.blr1.cdn.digitaloceanspaces.com/dropbox/Haryana_Steelers_x_Nuego.png`,
            "height": "350px",
            //"id": "Blog3"
        },
        {
            "heading":"NueGo partners with Delhi Capitals as associate sponsor for the upcoming cricket season",
            "paragrapgh":"NueGo, India’s leading intercity electric AC bus service from GreenCell Mobility, proudly announces its latest collaboration with Delhi Capitals for the upcoming cricket season, set to commence on February 23rd NueGo will partner as the Associate Sponsor for Delhi Capitals cricket franchise. NueGo brand logo will be featured on the leading trouser of the team’s official & training jersey, as part of the deal. This collaboration aligns with NueGo’s core values towards promoting eco-friendly travel and empowering women.",
            "link": "https://www.delhicapitals.in/news/nuego-partners-with-delhi-capitals-as-associate-sponsor-for-the-upcoming-cricket-season",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/NueGo%20x%20DC%20IPL.png`,
            "height": "350px",
            //"id": "Blog3"
        },
        {
            "heading":"GreenCell Mobility deploys 150 Electric Buses in Ayodhya to ferry over 2 Million Devotees",
            "paragrapgh":"GreenCell Mobility, a pioneering electric mobility solutions provider, is proud to announce that the Director of Urban Transport selected Greencell Mobility as a partner to deploy 150 intra-city electric buses to Ayodhya for the momentous Ram Mandir consecration ceremony. This fleet will play a crucial role in providing intra-city transportation service to an estimated 2 million devotees within Ayodhya from mid-January to the end of February, including the day of the ceremony and the surrounding days, marking a significant period for pilgrims and believers.",
            "link": "https://www.business-standard.com/companies/news/ram-mandir-opening-greencell-mobility-to-deploy-150-e-buses-in-ayodhya-124011600572_1.html",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/GCM%20Buses_Ayodhya_02.jpg`,
            "height": "350px",
            //"id": "Blog3"
        },
        {
            "heading":"GreenCell Mobility & EKA Mobility sign MoU to deploy 1000 electric buses",
            "paragrapgh":"GreenCell Mobility, a prominent player in the electric mass mobility space, and EKA Mobility, a leading EV & technology company (partnered with Mitsui Co., Ltd. & VDL Groep), announce the signing of a Memorandum of Understanding (MoU). As part of this agreement, EKA Mobility will supply GreenCell Mobility with 1000 intercity electric buses in the 12-meter and 13.5-meter category over the next few years. This initiative is projected to save INR 700 mn annually on fuel costs and prevent the burning of 12 mn gallons of diesel, equivalent to the effect of growing 1.5 mn trees. An estimated 0.6 mn individuals will benefit daily from the introduction of these 1000 e-buses. The deployment of these e-buses aims to reduce CO2 emissions by 32400 tonnes, aligning with both global and national sustainability objectives and fostering a cleaner environment.",
            "link": "https://www.financialexpress.com/business/express-mobility-eka-mobility-to-supply-1000-e-buses-to-greencell-mobility-3354520/",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/GCM-EKA.webp`,
            "height": "350px",
            //"id": "Blog3"
        },
        {
            "heading":"GreenCell Mobility Becomes the First Industry Player to Revolutionize EV Transport with Renewable Energy Alliance",
            "paragrapgh":"GreenCell Mobility, a pioneering force in the electric vehicle (EV) sector, is thrilled to announce a landmark achievement in sustainable transportation. Through its subsidiary, GreenCell Express, which operates the “NueGo” brand of inter-city electric buses, the company has inked a significant power purchase agreement and made a strategic equity investment in a 1 MW Wind Solar Hybrid captive power plant (with annual generation capacity of 4.6 million units) situated in Ratlam, Madhya Pradesh. This groundbreaking initiative marks an industry-first, as it will see its inter-city electric buses in the state being powered predominantly by renewable energy, leading to incremental CO2 savings of ~38000 tonnes over the lifetime of buses.",
            "link": "https://energy.economictimes.indiatimes.com/news/renewable/wind-solar-hybrid-plant-to-power-greencell-mobilitys-nuego-buses/106092802",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/newsroom7.jpg`,
            "height": "350px",
            // "id": "Blog3"
        },
       
       

        {
            "heading":'NueGo by GreenCell Mobility Forges a New Path in India’s Electric Transition with its new inter-city connections',
            "paragrapgh":' GreenCell Mobility, the trailblazer in electric mobility, accelerates its growth pace in India, with new inter-city routes for its premium AC electric bus service, NueGo. The company is set to elevate the landscape of electric travel with the introduction of new strategic routes that connect key cities in India, providing a sustainable and comfortable travel option to a wider audience.The recent route launches include Bhopal-Sagar, Hyderabad-Eluru, Delhi-Mussoorie, Gurgaon-Agra, Delhi-Ludhiana, Delhi-Shimla and Chennai-Bengaluru. The new routes are strategically designed to enhance connectivity between major cities, offering passengers a green and luxurious alternative for their journeys.',
            "link":"https://www.apnnews.com/nuego-by-greencell-mobility-forges-a-new-path-in-indias-electric-transition-with-its-new-inter-city-connections/",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/gcm-corp-news-2.png`,
            "height": "450px",
            //"id": "Blog3"
        },
        {
            "heading":"GreenCell Mobility’s premium electric bus service ‘NueGo’ to link Bhopal-Sagar and Hyderabad-Eluru",
            "paragrapgh":"India’s leading electric mobility player NueGo by GreenCell Mobility, announces the extension its premium inter-city AC electric bus services, with the addition of two dynamic routes: Hyderabad-Eluru and Bhopal-Sagar. The brand is already successfully operating from Indore-Bhopal in Madhya Pradesh and Hyderabad-Vijayawada in the Telangana-Andhra Pradesh corridor, offering a seamless booking process, premium travel experience and a safer, more sustainable ride. ",
            "link":"https://www.cmv360.com/news/greencell-mobilitys-nuego-electric-bus-service-expands-with-new-routes-connecting-indias-key-cities ",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/bhopal.png`,
            "height": "350px",
            //"id": "Blog2"
        },
        {
            "heading": 'GreenCell Mobility becomes the first E-Mobility player globally to secure a Green Loan Project Finance Facility by Standard Chartered Bank',
            "paragrapgh": "GreenCell Mobility, a pioneer in India's electric mobility sector, is thrilled to announce its green financing deal with the leading global bank, Standard Chartered Bank to revolutionise sustainable urban transportation in India. The company has successfully secured a green Project Finance loan to finance their Surat E-Mobility project, which consists of 150 electric buses in Gujarat, making it a notable financing partnership in India's electric mobility sector. The accomplishment was made possible with Standard Chartered Bank, which served as the sole mandated arranger, lender, and green loan coordinator for the Rs. 1.25 billion Project Finance Facility.",
            "link": "https://www.thehindubusinessline.com/companies/greencell-secures-project-finance-loan-from-stanchart-for-its-125-cr-e-bus-project/article67356727.ece  ",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/newsRoom1.jpg`,
            "height": "450px",
            //"id": "Blog3"
        },
        {
            "heading":"McDonald's India - North and East Partners with GreenCell Mobility to Offer Meals on NueGo Electric Bus Route",
            "paragrapgh":"Demonstrating its unwavering dedication to environmental responsibility, NueGo, the foremost electric passenger coach service in India operated by GreenCell Mobility, is collaborating with the renowned global food chain, McDonald’s India – North and East. Travelers opting for NueGo bus service from the Inter State Bus Terminal (ISBT), New Delhi to popular destinations like Chandigarh, Ludhiana, Dehradun, Agra or Jaipur - can now place orders for McDonald’s food, while waiting in the exclusive lounge. The partnership will gradually extend to more routes and introduce pre-booking option for meals to add to the travelers’ convenience. ",
            "link":"https://hospitality.economictimes.indiatimes.com/news/operations/food-and-beverages/mcdonalds-india-north-and-east-partners-with-greencell-mobility/103206227#:~:text=McDonald's%20India%20%E2%80%93%20North%20and%20East,promoting%20environmentally%20friendly%20mobility%20solutions.",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/newsRoom4.jpg`,
            "height": "420px",
        },
        {
            "heading":"GreenCell Mobility’s NueGo Becomes The First Commercial Vehicle To Be Registered In Tamil Nadu",
            "paragrapgh":"GreenCell Mobility’s NueGo becomes the first battery operated transport vehicle to be registered in the State of Tamil Nadu, following the Government of Tamil Nadu’s order stating that all Passenger Vehicles which are battery-operated shall be issued permits by the Transport Department. Commenting on this milestone, Mr. Devndra Chawla, MD & CEO of GreenCell Mobility said, “We're electrified and honored to be the pioneers of change in Tamil Nadu! With the support of the government's forward-thinking policies, we are proud to be the first battery operated transport vehicle to be registered, paving the way for a greener, sustainable future in transportation.”",
            "link":"https://auto.economictimes.indiatimes.com/news/commercial-vehicle/mhcv/greencells-nuego-battery-operated-buses-to-soon-zip-through-roads-in-tn/103123706",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/newsRoom5.jpg`,
            "height": "350px",

        },
        {
            "heading":"NueGo launches Independence Day special campaign, #Bus1RupeeMein, offering all tickets for 15th August for just ₹1!",
            "paragrapgh":"NueGo, India's leading intercity electric AC coach service from GreenCell Mobility, is thrilled to announce its exciting new campaign to celebrate Independence Day. From 10th to 15th August 2023, customers can book tickets for any operating routes of the brand for an unbelievable price of just Re. 1. This limited-time offer is valid for travel on 15th August, marking the nation's 77th Independence Day.",
            "link":"https://government.economictimes.indiatimes.com/news/economy/travel-at-rs-1-on-independence-day-nuego-launches-bus1rupeemein-campaign-from-august-10-to-15/102649125#:~:text=Economy-,Travel%20at%20Rs%201%20on%20Independence%20Day%3A%20NueGo%20launches%20'Bus1RupeeMein,10th%20to%20August%2015th%2C%202023.",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/newsRoom6.png`,
            "height": "430px",
        }
   ,
        {
            "heading": 'GreenCell Mobility Secures INR 3,000 Crores Debt Funding from REC for Sustainable Transportation Services',
            "paragrapgh": "GreenCell Mobility, a leading pan-India shared electric mobility player, proudly announces the signing of a momentous Memorandum of Understanding (MOU) with REC Limited, a prestigious Maharatna Central Public-sector enterprise. The recently signed MOU solidifies a substantial financial commitment of INR 3,000 crores from Rural Electrification Corporation (REC) Limited towards bolstering GreenCell Mobility's mission to revolutionize sustainable transportation in India.",
            "link": "https://www.business-standard.com/companies/news/greencell-mobility-receives-rs-3-000-crore-in-debt-funding-from-rec-123072400708_1.html",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/arpit1.jpg`,
            "height": "380px",
            // "id": "Blog1"

        },


        {
            "heading": 'NueGo receives prestigious ‘Asia Book of Records’ and ‘India Book of Records’ citations',
            "paragrapgh": "NueGo took a step towards bringing electric vehicles and women's empowerment together by doing the world's first ever all-woman electric intercity bus ride between Delhi and Agra on 16th May 2023. The bus was driven by a woman coach captain, hosted by a woman coach host, and carried only women passengers.This revolutionary journey was flagged off by Mr.Devndra Chawla, CEO & MD of GreenCell Mobility, with an aim to break barriers, shatter stereotypes, and celebrate the incredible strength and resilience of women. As part of its initiatives during Pride Month, GreenCell Mobility is pleased to highlight the contributions of its LGBTQIA+ employees, who play integral roles across various departments, such as Coach Captains, Command Centre, Operations, Finance and Administration.",
            "link": "https://emobilityplus.com/2023/05/19/nuego-launches-indias-first-all-female-intercity-electric-bus",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/blog1.jpg`,
            "height": "380px",
            // "id": "Blog2"

        },
        {
            "heading": 'NueGo, India‘s premium electric bus brand flags off World’s “first all-women intercity bus”',
            "paragrapgh": " “As the world's first bus brand to launch an all-women intercity electric bus, we are delighted to set this milestone. Our commitment is to ensure a fair and equitable representation of women in our workforce. We take immense pride in promoting women to positions of influence and challenging traditional industry norms that have long been associated with male dominance in certain roles. Our goal is to build India's first national electric bus mass mobility brand,with a strong emphasis on empowering women.”",
            "link": "https://emobilityplus.com/2023/05/19/nuego-launches-indias-first-all-female-intercity-electric-bus",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/blog2.jpg`,
            "height": "400px",
            // "id": "Blog3"

        },
        {
            "heading": 'GreenCell Mobility appoints Devndra Chawla as CEO',
            "paragrapgh": "Chawla will lead the company’s objective of being the prime mover in building sustainable growth momentum in the shared e-mobility space. He will report to the board of GreenCell. GreenCell Mobility is backed by Eversource Capital, the investment manager for India’s largest climate fund, Green Growth Equity Fund (GGEF).",
            "link": "https://auto.economictimes.indiatimes.com/amp/news/industry/greencell-mobility-appoints-devndra-chawla-as-ceo/97190105",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/newsroom1.jpg`,
            "height": "400px",
            // "id": "Blog3"

        },
        {
            "heading": 'GreenCell Mobility bags order of Electric Buses from CESL under National E-Bus Program Phase-I',
            "paragrapgh": "GreenCell Mobility Ltd (GreenCell), a leading pan-India shared electric mobility player has bagged order from Transport Department, Government of NCT Delhi for 570 electric buses. The tender was floated by Convergence Energy Services Ltd (CESL), a Government of India undertaking, under National E-bus Program (NEBP). This contract for 6,465 E-Buses under Gross Cost Contracting (GCC) basis is the largest tender for E-buses ever floated in India.",
            "link": "https://www.financialexpress.com/express-mobility/greencell-bags-cesl-tender-for-providing-570-e-buses-to-delhi-government/2940884",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/newsroom2.jpg`,
            "height": "350px",
            // "id": "Blog4"
        },
        {
            "heading": 'Greencell receives $55 million sanction from leading DFI’S ADB, AIIB & grant of $5.2 million from Goldman Sachs and Bloomberg for safer e-buses in India',
            "paragrapgh": " The Asian Development Bank (ADB), Asian Infrastructure Investment Bank (AIIB) and Clean Technology Fund (CTF) signed a $55 million financing package with GreenCell Express Private Limited (GEPL) to develop 255 electric battery-powered buses (e-buses) to serve 5 million people a year on 56 intercity routes in India and enhance the safety of passengers, especially women, through improved security features including cameras, tracking and panic buttons connected to command controls for immediate response.",
            "link": "https://auto.economictimes.indiatimes.com/amp/news/industry/greencell-mobility-appoints-devndra-chawla-as-ceo/97190105",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/gcm-corp-news-1.png`,
            "height": "380px",
            // "id": "Blog5"
        },
        {
            "heading": ' GreenCell Mobility to deploy first intercity e-bus for MSRTC on 1st June ',
            "paragrapgh": "Starting June 1st, these electric buses, dubbed ‘Shivai’ will be introduced across the state in stages. GreenCell Mobility, offering electric mobility as service platform, would run the first intercity e-bus for Maharashtra Regional State Transport (MSRTC) from the city. Marking the commemoration day of MSRTC’s foundation, the bus would be running on the Pune-Ahmednagar route. GreenCell Mobility would be deploying 50 such electric buses for intercity travel across Maharashtra for MSRTC in the cities of Pune, Aurangabad, Kolhapur, Nasik and Solapur soon.",
            "link": "https://auto.hindustantimes.com/auto/news/greencell-mobility-to-deploy-50-electric-buses-in-four-districts-of-maharashtra-41652690869024.html",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/newsroom6.jpg`,
            "height": "380px",
            // "id": "Blog6"
        },
        {
            "heading": 'GreenCell Mobility launches intercity coach NueGo',
            "paragrapgh": "Shared electric mobility player GreenCell Mobility on Thursday announced the launch of intercity coach brand ‘NueGo’ with an initial plan to have services across 24 cities in India. In the initial phase, 250 electric buses will connect 24 cities, while the overall plan is to roll out 750 premium air conditioned e-buses across key intercity routes in southern, northern and western India covering key transit routes with coverage in over 75 cities, the company said in a statement.",
            "link": "https://economictimes.indiatimes.com/industry/renewables/greencell-mobility-launches-intercity-coach-nuego/articleshow/90979476.cms",
            "image": `${process.env.REACT_APP_IMAGEBASE_URL}/newsroom7.jpg`,
            "height": "380px",
            // "id": "Blog7"

        }


        
        
   
    

    ]

    return (
        <Layout>
            <section className='newsbannersec'>
                <div className="container">
                    <div className="row">
                        <div className='col-12'>
                            <h2 className='headingRout'> Home / <span> News </span> </h2>
                            <div className='newsbanner_part'>
                                <h1>GreenCell News: Unveiling the Latest Stories and Updates</h1>
                                <figure>
                                    <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/newsbanner.png`} alt='newsbanner img' />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Mainnews_section'  >
                <div className="container">

                    <div className="row">
                        <div className='col-12'>
                            <ul className='containerBox'>
                                {array.map((items, id) => {

                                    return <li id={items.id}>
                                        <div className='contentBox'>
                                            <h4>
                                                {items.heading}
                                            </h4>
                                            <p dangerouslySetInnerHTML={{__html: items.paragrapgh}}>
                                                
                                            </p>
                                            <a href={items.link} target='_blank' className='ReadMoreBtn'>Read More
                                             {/* <img src={ReadMorearrow} alt='img' /> */}
                                             <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/arrow.svg`} alt='img' /> 

                                              </a>
                                        </div>
                                        <div className='imageBox'>
                                            <img style={{height:items.height , objectFit:items?.objectFit}} src={items.image} />
                                        </div>
                                    </li>
                                })}
                            </ul>
                        </div>


                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default NewsPage

